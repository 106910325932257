<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} gate pass` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cancelForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                @change="selectUser"
                                v-model="selectedUser"
                                outlined
                                dense
                                :items="users"
                                hide-selected
                                item-text="display_text"
                                item-value="id"
                                :search-input.sync="userSearch"
                                @input="gatePass.user_id = $event !== null ? $event : ''" 
                                :loading="isUserLoading"
                                clearable
                                :error="$v.gatePass.user_id.$error"
                            >
                            <template v-slot:label>
                                <span class="text-danger">*</span> Search by name, email or CRN
                            </template>
                            <template v-slot:no-data>
                                <div class="no-data-auto-complete text-center">No data available to display. </div>
                            </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.gatePass.user_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Reason <span class="text-danger">*</span>
                            </label>
                            <ckeditor v-model="gatePass.reason" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.gatePass.reason.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn 
                                    depressed 
                                    @click="cancelForm" 
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>

                                <v-btn 
                                    depressed 
                                    :loading="isBusy"
                                    @click="createOrUpdate" 
                                    class="text-white ml-2 btn btn-primary"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import GatePassService from "@/core/services/gate-pass/GatePassService";
import UserService from "@/core/services/user/UserService";

const gatePass = new GatePassService();
const user = new UserService();

export default {
    validations: {
        gatePass: {
            user_id: { required },
            reason: { required },
        }
    },
    data() {
        return {
            users: [],
            dialog: false,
            edit: false,
            isBusy: false,
            editorConfig: {
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            gatePass: {
                user_id: null,
                reason: '',
            },
            isUserLoading: false,
            userSearch: ''
        };
    },
    methods: {
        selectedUser(val)
        {
            if(!val){
                this.gatePass.user_id ='';
            }
        },
        selectUser()
        {
            if(this.selectedUser && this.selectedUser != null){
                this.gatePass.user_id = this.selectedUser;
            }else{
                this.gatePass.user_id = "";
            }
        },
        showModal(id = null) {
            if (id) {
                this.edit = true
                this.getGatePass(id)
            }else{
                this.edit = false;
                this.resetForm();
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        getGatePass(id) {
            gatePass
            .show(id)
            .then(response => {
                this.gatePass = response.data.gatePass;
                if(this.gatePass.user_id){
                    this.searchUser(this.gatePass.user_email)
                }
            })
            .catch(err => {

            })
            .finally(() => {

            })
        },
        searchUser(userEmail){
            this.isUserLoading = true;
            
            user
            .search(userEmail)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id + 
                        " | " +
                        user.email
                    this.users.push(data);
                });
                this.selectedUser = response.data.users[0];
            })
            .catch((err) => {

            })
            .finally(() => (this.isUserLoading = false));
        },
        createOrUpdate() {
            this.$v.gatePass.$touch();
            if (this.$v.gatePass.$error) {
                setTimeout(() => {
                    this.$v.gatePass.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateGatePass();
                } else {
                    this.createGatePass(this.gatePass);
                }
            }
        },
        updateGatePass() {
            this.isBusy = true;
            gatePass
            .update(this.gatePass.id, this.gatePass)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
                this.hideModal();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createGatePass(fd) {
            this.isBusy = true;
            gatePass
            .store(this.gatePass)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$emit("refresh");
                this.hideModal();
                this.$snotify.success("Information added");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.gatePass.$reset();
            this.edit = false;
            this.gatePass = {
                user_id: null,
                reason: ''
            };
            this.userSearch = '';
        },
        cancelForm(){
            this.resetForm();
            this.hideModal();
        }
    },
    watch:{
        userSearch(val) {
            this.isUserLoading = true;
            
            user
            .search(val)
            .then((response) => {
                    response.data.users.map((user) => {
                    let data = user;
                    data.display_text =
                        user.full_name +
                        " | " +
                        user.personal_id + 
                        " | " +
                        user.email
                    this.users.push(data);
                });
            })
            .catch((err) => {

            })
            .finally(() => (this.isUserLoading = false));
      },
    }
}
</script>
