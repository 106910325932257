import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'
const apiService = Apiservice;


export default class GatePassService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/gate-pass`;
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
                url = `${url}?page=${index}`;
        let param ={
                params: data
        }
        return apiService.query(url,param);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);
    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }
    showLoggedInPass(){
        
        let url = `${this.#api}/logged-in`
        return apiService.get(url);
    }

    getPendingGatePassCounts(){
        let url = `${this.#api}/pending/count`
        return apiService.get(url);
    }
}

